<template>
  <div>
    <!-- picBBC31F05B7E247B673EBAFE9450C0CD8.jpg -->
    <img class="rounded img-large" src="" />

    <p>Brickkeeper says:</p>
    <p>
      <i
        >Most minifigures had amazing pets before the Maelstrom was unleashed:
        Parrots, puppies, prancing horses, and more!</i
      >
    </p>
    <p>
      <i
        >But now the dark menace has infected many of our animals, turning them
        into stromlings! Drained of creativity, the dark creatures are driven to
        cause chaos and destruction! <br />Come to think of it, my robo-dog
        M.U.T.T. was kind of like that before he was house-trained... Except for
        the 'no creativity' part. I mean, he once took control of a toy tank to
        blast into the cabinet where I keep his treats!</i
      >
    </p>
    <p>
      <i
        >My data says we can push back the Maelstrom with new sidekick
        critters-- but we need your creativity!</i
      >
    </p>
    <p>
      Take off for the
      <!--FIXME: <router-link
        :to="{
          name: 'creation-lab-list',
          params: { challengeId: '1f26e0da-d457-4b68-ac06-ba5053baa5ab' },
        }"
      >
        Creation Lab
      </router-link>-->
    </p>
  </div>
</template>
